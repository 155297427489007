import "./styles.scss";

import React from "react";
import Button from "components/Button";

const mainClass = "info-page__content";

const Content = ({ title, text, sub_text }) => {
  return (
    <div className={mainClass}>
      <div className={`${mainClass}__header`}>
        <div className="container">
          <h1>{title}</h1>
        </div>
      </div>
      <div className={`${mainClass}__content`}>
        <div className="container">
          <span>{text}</span>
          <span className="small">{sub_text}</span>
          <Button href="/">Strona główna</Button>
        </div>
      </div>
    </div>
  );
};

export default Content;
