import React from "react";

import Layout from "components/Layout";
import Seo from "components/Layout/components/Seo";
import Content from "page_components/info_page/Content";

const ThankYou = () => {
  return (
    <Layout hide_contact_section>
      <Content
        title="Wiadomość wysłana"
        text="Dziękujemy za kontakt. Twoja wiadomość została wysłana."
        sub_text="Nasi specjaliści skontaktują się z Państwem najszybciej jak to będzie możliwe i udziela wszystkich informacji na temat inwestycji."
      />
    </Layout>
  );
};

export const Head = () => <Seo title="Wiadomość wysłana | Brzegova" />;

export default ThankYou;
